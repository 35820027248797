<script setup lang="ts">
import { ref, computed } from 'vue';

import { type GroupLoanApplication } from '@/api/groupLoanApplication';
import type { GroupMember } from '@/api/groupMember';
import BaseTable from '@/components/base-table.vue';
import copyPreApprovalTableAsJpg from '@/utils/copyPreApprovalTableAsJpg';
import { toCurrency } from '@/utils/filters';
import LoanUtils from '@/utils/loanUtils.ts';

interface Props {
  groupLoanApplication: GroupLoanApplication;
  groupMembers: GroupMember[]
}

const props = defineProps<Props>();

const copyButtonText = ref('Copiar tabla');

const filteredAndOrderedMemberLoanApplications = computed(() => props.groupLoanApplication.memberLoanApplications
  .filter(mla => mla.preApproved)
  .map(mla => {
    const groupMember = props.groupMembers.find(member => member.id === mla.groupMemberId) as GroupMember;

    return { mla, groupMember };
  })
  .sort((a, b) => {
    const roleOrder = { president: 1, 'vice_president': 2, guest: 3 };

    return roleOrder[a.groupMember.role] - roleOrder[b.groupMember.role];
  }));

const tableItems = computed(() => filteredAndOrderedMemberLoanApplications.value.map(({ mla, groupMember }) => {
  const acceptedAmount = mla.acceptedAmount ?? 0;
  const requestedAmount = mla.amount ?? 0;

  const periodPayment = Math.ceil(LoanUtils.calculateLoanPayment(
    acceptedAmount,
    mla.interestRate,
    props.groupLoanApplication.repaymentPeriods,
  ));

  const costPerThousand = LoanUtils.costPerThousand(
    mla.interestRate,
    props.groupLoanApplication.repaymentPeriods,
  );

  const humanRole = ['president', 'vice_president'].includes(groupMember.role) ? `(${groupMember.humanRole})` : '';

  return [
    `${groupMember.user.fullName} ${humanRole}`,
    toCurrency(requestedAmount),
    toCurrency(acceptedAmount),
    toCurrency(periodPayment),
    toCurrency(costPerThousand),
  ];
}));

function handleCopyTable() {
  copyPreApprovalTableAsJpg(props.groupLoanApplication);
  copyButtonText.value = 'Copiada!';
  setTimeout(() => {
    copyButtonText.value = 'Copiar tabla';
  }, 2000); // eslint-disable-line no-magic-numbers
}

</script>

<template>
  <div class="flex flex-col gap-y-5">
    <base-table
      v-if="tableItems.length > 0"
      ref="table"
      title="Resumen de miembros pre-aprobados"
      :headers="[
        'Nombre',
        'Monto solicitado',
        'Monto',
        'Pago semanal',
        'Pago / mil',
      ]"
      :items="tableItems"
    />
    <div class="flex justify-end">
      <base-button
        variant="text"
        :text="copyButtonText"
        @click="handleCopyTable"
      />
    </div>
  </div>
</template>
