<script setup lang="ts">
import { useForm, useFieldArray } from 'vee-validate';
import { toRef } from 'vue';
import { array, mixed, object, string } from 'yup';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { KycUploadBody } from '@/api/groupMemberLoanApplication/support/';
import { useUploadKyc } from '@/api/groupMemberLoanApplication/support/mutations';
import BaseButton from '@/components/base-button.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';
import VeeValidateInput from '@/components/vee-validate/vee-validate-input.vue';
import VeeValidatePhoneInput from '@/components/vee-validate/vee-validate-phone-input.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import useUiStore from '@/stores/ui';

const MIN_REFERENCES = 2;

const uiStore = useUiStore();

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
}
const props = defineProps<Props>();

const validationSchema = object({
  idFront: mixed<File>().when('idFrontIsPresent', {
    is: () => !props.groupMemberLoanApplication.idFrontUrl,
    then: (schema) => schema.required('INE (Frontal) es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  idBack: mixed<File>().when('idBackIsPresent', {
    is: () => !props.groupMemberLoanApplication.idBackUrl,
    then: (schema) => schema.required('INE (Reverso) es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  idSelfie: mixed<File>().when('idSelfieIsPresent', {
    is: () => !props.groupMemberLoanApplication.idSelfieUrl,
    then: (schema) => schema.required('Selfie con INE es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  addressProof: mixed<File>().when('addressProofIsPresent', {
    is: () => !props.groupMemberLoanApplication.addressProofUrl,
    then: (schema) => schema.required('Comprobante de domicilio es un campo requerido'),
    otherwise: (schema) => schema.nullable(),
  }),
  references: array().of(
    object({
      name: string().required('Name is required'),
      phoneNumber: string().mobilePhone(['es-MX', 'es-CL']).required('Phone is required'),
      relationship: string().required('Relationship is required'),
    }),
  ).min(MIN_REFERENCES, 'At least one reference is required'),
});

const uploadKycMutations = useUploadKyc({
  groupMemberLoanApplicationId: toRef(props.groupMemberLoanApplication, 'id'),
  onSuccess: () => uiStore.toast({
    message: 'KYC enviado correctamente',
    type: 'success',
    position: 'bottom',
  }),
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al validar la CURP',
      type: 'error',
      position: 'bottom',
    });
  },
});

const { handleSubmit, values: formValues } = useForm<KycUploadBody>({
  validationSchema,
  initialValues: {
    references: [
      ...props.groupMemberLoanApplication.references,
      { name: '', phoneNumber: '', relationship: '' },
      { name: '', phoneNumber: '', relationship: '' },
    ],
  },
});

const { remove: removeReference, push } = useFieldArray('references');

function addReference() {
  push({ name: '', phoneNumber: '', relationship: '' });
}

const onSubmit = handleSubmit((values) => {
  uploadKycMutations.mutate(values);
});

</script>

<template>
  <form
    class="flex flex-col gap-y-6 rounded-lg bg-white p-6"
    @submit="onSubmit"
  >
    <VeeValidateFileInput
      input-id="front-id"
      name="idFront"
      label="INE (frontal)"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="props.groupMemberLoanApplication.idFrontUrl"
      initial-file-name="INE (frontal)"
    />
    <VeeValidateFileInput
      input-id="back-id"
      name="idBack"
      label="INE (reverso)"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="props.groupMemberLoanApplication.idBackUrl"
      initial-file-name="INE (reverso)"
    />
    <VeeValidateFileInput
      input-id="selfie-id"
      name="idSelfie"
      label="Selfie con INE"
      extensions=".JPG, .JPEG, .PNG"
      :initial-file-url="props.groupMemberLoanApplication.idSelfieUrl"
      initial-file-name="Selfie con INE"
    />
    <VeeValidateFileInput
      input-id="address-proof"
      name="addressProof"
      label="Comprobante de domicilio"
      extensions=".JPG, .JPEG, .PNG, .PDF"
      :initial-file-url="props.groupMemberLoanApplication.addressProofUrl"
      initial-file-name="Comprobante de domicilio"
    />

    <div class="flex flex-col gap-y-4">
      <h3 class="text-lg">
        Referencias
      </h3>
      <p class="text-gray-600">
        Deben ser mínimo 2 referencias
      </p>
      <div
        v-for="(_, index) in formValues.references"
        :key="index"
        class="flex justify-between gap-x-4"
      >
        <VeeValidateInput
          :name="`references[${index}].name`"
          label="Nombre"
          class="flex-1"
        />
        <VeeValidatePhoneInput
          :name="`references[${index}].phoneNumber`"
          label="Número de teléfono"
          class="flex-1"
        />
        <VeeValidateSelect
          :name="`references[${index}].relationship`"
          label="Relación"
          class="flex-1"
          :options="[
            { label: 'Familiar cercano (hermana/a,  madre, hija/o, padre) ', value: 'close_relative' },
            { label: 'Familiar segundo (prima/o, tía, abuela/o)', value: 'distant_relative' },
            { label: 'Familiar político (cuñada/o, suegra/o, yerna/o)', value: 'in_law_relative' },
            { label: 'Pareja (esposo/a, novio/a)', value: 'partner' },
            { label: 'Amistad', value: 'friend' },
            { label: 'Vecino/a', value: 'neighbor' },
            { label: 'Conocido/a', value: 'acquaintance' },
          ]"
        />
        <BaseButton
          class="h-12 self-center"
          type="button"
          text="Remove"
          :disabled="formValues.references.length <= 2"
          @click="removeReference(index)"
        />
      </div>
      <BaseButton
        type="button"
        variant="text"
        text="Add Reference"
        @click="addReference"
      />
    </div>

    <BaseButton
      type="submit"
      text="Enviar"
      :loading="uploadKycMutations.isPending"
    />
  </form>
</template>
