<script setup lang="ts">
import { computed, toRef } from 'vue';

import type { Group } from '@/api/group';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useFetchGroupMemberLoanApplication } from '@/api/groupMemberLoanApplication/queries';
import BaseBadge from '@/components/base-badge.vue';
import KycForm from '@/components/support/group-member-loan-application/kyc-form.vue';
import SignatureForm from '@/components/support/group-member-loan-application/signature-form.vue';
import SubmissionForm from '@/components/support/group-member-loan-application/submission-form.vue';
import { groupMemberLoanApplicationBadgeColor } from '@/utils/states';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication;
  group: Group;
}
const props = defineProps<Props>();

const fetchGroupMemberLoanApplicationQ = useFetchGroupMemberLoanApplication({
  id: toRef(props.groupMemberLoanApplication, 'id'),
  initialData: props.groupMemberLoanApplication,
});

const groupMemberLoanApplicationTitle = computed(() => {
  if (!fetchGroupMemberLoanApplicationQ.data) return '';

  return `
  ${fetchGroupMemberLoanApplicationQ.data.groupMember.user.fullName} (${fetchGroupMemberLoanApplicationQ.data.publicId})
  `;
});

const groupTitle = computed(() => `${props.group.name} (${props.group.publicId})`);

</script>

<template>
  <div
    v-if="!!fetchGroupMemberLoanApplicationQ.data"
    class="container"
  >
    <div class="mb-4 flex flex-col text-lg font-semibold">
      <div class="flex items-center gap-x-4">
        <h3>
          Nombre: {{ groupMemberLoanApplicationTitle }}
        </h3>
        <BaseBadge
          :color="groupMemberLoanApplicationBadgeColor(fetchGroupMemberLoanApplicationQ.data.aasmState)"
          :label="$t(`groupMemberLoanApplication.aasmState.${fetchGroupMemberLoanApplicationQ.data.aasmState}`)"
        />
      </div>
      <h3>
        Grupo: {{ groupTitle }}
      </h3>
    </div>
    <SubmissionForm
      v-if="fetchGroupMemberLoanApplicationQ.data.aasmState === 'draft'"
      :group-member-loan-application="fetchGroupMemberLoanApplicationQ.data"
    />
    <KycForm
      v-else-if="fetchGroupMemberLoanApplicationQ.data.aasmState === 'in_kyc'"
      :group-member-loan-application="fetchGroupMemberLoanApplicationQ.data"
    />
    <SignatureForm
      v-else-if="fetchGroupMemberLoanApplicationQ.data.aasmState === 'contract_signature_pending'"
      :group-member-loan-application="fetchGroupMemberLoanApplicationQ.data"
    />
    <div
      v-else
      class="flex w-full flex-col items-center justify-center rounded-lg bg-white py-6 text-center"
    >
      <p>
        La solicitud está en estado
        <span class="font-bold text-primary-700">
          {{ $t(`groupMemberLoanApplication.aasmState.${fetchGroupMemberLoanApplicationQ.data.aasmState}`) }}.
        </span>
        De momento no hay acciones disponibles.
      </p>
      <p>
        Solo hay acciones disponibles cuando la solicitud está en estado
        {{ $t('groupMemberLoanApplication.aasmState.draft') }},
        {{ $t('groupMemberLoanApplication.aasmState.in_kyc') }} o
        {{ $t('groupMemberLoanApplication.aasmState.contract_signature_pending') }}.
      </p>
    </div>
  </div>
</template>
